import { createApp } from 'vue'
import { router } from '@/router'

import App from '@/App.vue'
import '@/styles/global/index.scss'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBtshUYJtwKMuHfvQDdFxJsLiEwABvrRVY",
  authDomain: "theprivatecollection-8ebc6.firebaseapp.com",
  projectId: "theprivatecollection-8ebc6",
  storageBucket: "theprivatecollection-8ebc6.appspot.com",
  messagingSenderId: "1064204356209",
  appId: "1:1064204356209:web:e9e1392c26cccea1eb69d0",
  measurementId: "G-2V7HVZEZFZ"
};

// Initialize Firebase
initializeApp(firebaseConfig);
const app = createApp(App);
app.use(router);
app.mount("#app");
