import { Component } from 'vue'

export const Home = (): Promise<Component> => {
  return import(/* webpackChunkName: "Upload" */ '@/pages/Home/Home.vue')
}


export const Upload = (): Promise<Component> => {
  return import(/* webpackChunkName: "Upload" */ '@/pages/Upload/Upload.vue')
}

export const MyUploads = (): Promise<Component> => {
  return import(/* webpackChunkName: "MyUploads" */ '@/pages/MyUploads/MyUploads.vue')
}


export const Signin = (): Promise<Component> => {
  return import(/* webpackChunkName: "Media" */ '@/pages/Signin/Signin.vue')
}

export const Signup = (): Promise<Component> => {
  return import(/* webpackChunkName: "Media" */ '@/pages/Signup/Signup.vue')
}

export const Media = (): Promise<Component> => {
  return import(/* webpackChunkName: "Media" */ '@/pages/Media/Media.vue')
}
