import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { environment } from '@/configs/environment'
import { getAuth, onAuthStateChanged } from "firebase/auth"



import { Upload, MyUploads, Media, Signin, Signup, Home} from './lazyRoutes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload,
    meta: {
      title: 'Upload',
      requiresAuth: true
    },
  },
  {
    path: '/my-uploads',
    name: 'MyUploads',
    component: MyUploads,
    meta: {
      title: 'My Uploads',
      requiresAuth: true
    },
  },
  {
    path: '/sign-in',
    name: 'Signin',
    component: Signin,
    meta: {
      title: 'Sign in',
    },
  },
  {
    path: '/sign-up',
    name: 'Signup',
    component: Signup,
    meta: {
      title: 'Sign up',
    },
  },
  {
    path: '/media/uploads/:id',
    name: 'Media',
    props: true,
    component: Media
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    redirect: '/',
  },
]

export const router = createRouter({
  history: createWebHistory(environment.appUrl),
  routes,
})

const getCurrentUser = () => {
  return new Promise ((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      next("/");
    }

  }else {
    next();
  }
})
