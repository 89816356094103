<template>
  <RouterView />
</template>

<script setup>

import { onMounted, ref } from "vue";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const isLoggedIn = ref(false);

let auth;
onMounted (() => {
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      isLoggedIn.value = true;
    } else {
      isLoggedIn.value = false;
    }
    console.log(isLoggedIn)
  });
});

const handleSignOut = () => {
  signOut(auth).then( () => {
    router.push("/");
  });
};

</script>
<style scoped>
.Navbar__back {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Navbar__back:hover {
  background-color: #d0d0d0;
}

@media screen and (max-width: 480px) {
  .Navbar__back {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
  }
}
</style>